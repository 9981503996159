import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Popper } from "@mui/base/Popper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { fontRobotoCondensed, transientOptions } from "@product/scmp-sdk";
import { DateRange } from "react-date-range";

import { ZIndexLayer } from "shared/lib/styles";

export const Container = styled.div``;

type StyledButtonProps = {
  $open: boolean;
};

<ArrowDropDownIcon />;
export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  inline-size: 16px;
  block-size: 16px;
`;

export const StyledButton = styled(Button, { ...transientOptions })<StyledButtonProps>`
  padding-block: 4px;
  padding-inline: 8px;

  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;
  letter-spacing: 0.19px;
  text-transform: capitalize;

  border: 1px solid #4585ff;
  border-radius: 2px;

  background-color: rgba(21, 30, 47, 0);

  ${props =>
    props.$open &&
    css`
      color: #000000;

      border-color: #000000;
    `}

  > span {
    display: flex;
    align-items: center;

    inline-size: 16px;
    block-size: 16px;
    margin-inline: 9px 0;
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: ${ZIndexLayer.PlusDatePicker};
`;

export const StyledBox = styled(Box)`
  display: grid;
  grid:
    "defined calendar" min-content
    "action action" min-content
    / 200px 1fr;

  border: 1px solid #999999;
  border-radius: 4px;

  background-color: #ffffff;
`;

export const DefinedDateRangeContainer = styled.div`
  grid-area: defined;

  display: flex;
  flex-flow: column nowrap;

  padding-block: 12px;

  border-inline-end: 1px solid #999999;
`;

export const DefinedDateRangeButton = styled(Button)`
  display: flex;
  justify-content: flex-start;

  padding-block: 12px;
  padding-inline: 20px;

  color: #000000;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  text-transform: capitalize;
`;

export const SpecificDateText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-block: 12px;
  padding-inline: 20px;

  color: #4585ff;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  text-align: center;
  column-gap: 12px;
`;

export const StyledDateRange = styled(DateRange)`
  grid-area: calendar;

  padding: 20px;

  ${".rdrDateDisplayWrapper"}, ${".rdrNextPrevButton"} {
    background-color: transparent;
  }
  ${".rdrMonthAndYearWrapper"}, ${".rdrMonth"} {
    padding: 0;
  }
  ${".rdrMonthAndYearWrapper"} {
    block-size: unset;
    margin-block-start: 8px;
  }
  ${".rdrDateInput"} {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 12px;
    font-family: ${fontRobotoCondensed};
    text-align: center;

    border: none;
    border-radius: 0;

    box-shadow: none;
    border-block-end: 1px rgba(0, 0, 0, 0.5) solid;
  }
  ${".rdrDateDisplayItem"} + ${".rdrDateDisplayItem"} {
    margin-inline-start: 22px;
  }
  ${".rdrWeekDay"}, ${".rdrDayNumber"} span {
    color: #000000 !important;
    font-weight: 400;
    font-size: 12px;
    font-family: ${fontRobotoCondensed};
    text-align: center;
  }
  ${".rdrDayPassive"} {
    visibility: hidden;
  }
  ${".rdrDayToday"} {
    span::after {
      background-color: #000000 !important;
    }
  }
  ${".rdrMonthPicker"} select, ${".rdrYearPicker"} select {
    padding: 10px;

    color: #000000;
    font-weight: 700;
    font-size: 12px;
    font-family: ${fontRobotoCondensed};
    text-align: center;

    background: none;
  }
`;

export const ActionButtonContainer = styled.div`
  grid-area: action;

  display: flex;

  padding-block: 16px;
  padding-inline: 20px;

  column-gap: 20px;

  border-block-start: 1px solid #999999;
`;

export const StyledChip = styled(Chip)`
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  text-transform: capitalize;

  border-radius: 8px;

  background-color: #4585ff;

  svg {
    color: #ffffff !important;
  }
`;
